<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section d-block padd-30 pt-0 pb-0">
              <div class="w-100 t-wrap">
                <h4 class="menu_header">New Menu</h4>
              </div>
              <div class="back-btn">
                <router-link :to="{ name: 'menu' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                </router-link>
                <span>{{ title }}</span>
              </div>
            </div>

            <div class="create_items_section">
              <form @submit.prevent="createMenu">
                <div class="row">
                  <div class="col-md-6 bd-right pad-lr-0">
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <label>Title</label>
                        <div>
                          <input class="form-control" v-model="title" @blur="v$.title.$touch">
                        </div>
                        <div class="input_error" v-if="v$.title.$error">This field is required.</div>
                      </div>
                    </div>

                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Menu Availability</h2>
                        <span>Menu is active. Customers can view and order from this menu</span>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <div>
                              <label class="radio-inline">
                                <input type="radio" id="vegan" v-model="daysettings" value="0" />
                                Custom Days &nbsp;
                              </label>
                              <label class="radio-inline">
                                <input type="radio" id="vegeterian" v-model="daysettings" value="1" />
                                Every Day &nbsp;
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="pl-5 pr-4 pt-4 mb-4">
                      <!-- div section for custom day selection -->
                      <div v-if="daysettings == 0">
                        <div class="row" id="period_div" v-for="(period, pindx) in periodTimeCount" :key="pindx">

                          <div class="col-1 order-last">
                            <span v-if="period.key !== 1" @click="removePeriodCountDivs(period.key, day[pindx])" class="div_remove_btn remove_icon glyphicon glyphicon-remove"></span>

                          </div>
                          <div class="col-md-11">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Day</label>
                                  <select class="form-control" v-model="day[pindx]">
                                    <option v-for="day in dayOfWeek" :key="day.key" :value="day.key">{{ day.value }}
                                    </option>
                                  </select>
                                  <div class="input_error" v-if="dayErr != ''">{{ dayErr }}</div>

                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Start Time</label>
                                  <select class="form-control" v-model="startTime[pindx]">
                                    <option v-for="time in startTimes" :key="time.key" :value="time.value">{{ time.key }}
                                    </option>
                                  </select>
                                  <div class="input_error" v-if="v$.startTime.$error">This field is required.</div>

                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>End Time</label>
                                  <select class="form-control" v-model="endTime[pindx]" name="end_time[]">
                                    <option v-for="(etime, index) in endTimes" :key="index" :value="etime.value">
                                      {{ etime.key }}</option>
                                  </select>
                                  <div class="input_error" v-if="v$.endTime.$error">This field is required.</div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="add_days_btn">
                          <label @click="createPeriodCountDivs" style="cursor:pointer;"><span class="glyphicon glyphicon-plus"></span> Add More Days Times</label>
                        </div>
                      </div>
                      <!-- everyday time -->
                      <div v-if="daysettings == 1">

                        <div class="row">

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Start Time</label>
                              <select class="form-control" v-model="startTime[0]">
                                <option v-for="time in startTimes" :key="time.key" :value="time.value">{{ time.key }}
                                </option>
                              </select>
                              <div class="input_error" v-if="v$.startTime.$error">This field is required.</div>

                            </div>

                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>End Time</label>
                              <select class="form-control" v-model="endTime[0]">
                                <option v-for="(etime, index) in endTimes" :key="index" :value="etime.value">{{ etime.key }}
                                </option>
                              </select>
                              <div class="input_error" v-if="v$.endTime.$error">This field is required.</div>

                            </div>

                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                  <!--Right Col-->
                  <div class="col-md-6 pad-l-0">
                    <div class="pl-4 pr-5 bd-b">
                      <h2 class="section-title">Branches To include</h2>
                      <div class="form-group">
                        <label>Branches To include</label>
                        <div>
                          <Multiselect v-model="branches" mode="tags" placeholder="Select Branches To include" track-by="name" label="name" :close-on-select="true" :searchable="true" :options="options">
                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                              <div class="multiselect-tag is-user">
                                {{ option.name }}
                                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                  <span class="multiselect-tag-remove-icon"></span>
                                </span>
                              </div>
                            </template>
                          </Multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="pl-4 pr-5 bd-b">
                      <h2 class="section-title">Manage Offers</h2>
                      <div class="form-group">
                        <label>Allow Generated Offers Categories </label>
                        <div>
                          <label class="radio-inline"><input type="radio" v-model="allowOfferCategories" :value="true" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                          <label class="radio-inline"><input type="radio" v-model="allowOfferCategories" :value="false"> &nbsp; No</label>

                        </div>
                      </div>
                    </div>
                  </div>

                  <!--  -->
                  <!-- <div class="form-group">
                      <label>Third parties To exclude</label>
                      <div>
                        <Multiselect v-model="thirdparties" mode="tags" placeholder="Select Third parties To exclude" track-by="name" label="name" :close-on-select="true" :searchable="true" :options="coptions">
                          <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div class="multiselect-tag is-user">
                              {{ option.name }}
                              <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                <span class="multiselect-tag-remove-icon"></span>
                              </span>
                            </div>
                          </template>
                        </Multiselect>
                      </div>
                    </div> -->

                  <hr>
                  <div class="row bt-b">
                    <div class="col-md-12">
                      <div class="form-group ml-5 mt-3">
                        <button type="submit" class="btn btn-orange mr-3">
                          <span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save </span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '../../../../mixins/ReusableFunctionMixins';
import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
    props: ['token'],
    components: {
        Multiselect
    },
    setup() {

        return { v$: useVuelidate() }
    },
    data() {
        return {
            spinner: 0,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving... ",
            title: '',
            startTime: [],
            endTime: [],
            options: [],
            branches: [],
            day: [],
            daysettings: 0,
            periodTimeCount: [{ 'key': 1 }],
            dayOfWeek: [{ "key": "monday", "value": "Monday" }, { "key": "tuesday", "value": "Tuesday" }, { "key": "wednesday", "value": "Wednesday" }, { "key": "thursday", "value": "Thursday" }, { "key": "friday", "value": "Friday" }, { "key": "saturday", "value": "Saturday" }, { "key": "sunday", "value": "Sunday" }],
            dayErr: '',
            coptions: [],
            thirdparties: [],
            allowOfferCategories: true,
        }
    },
    validations() {
        return {
            title: { required },
            endTime: { required },
            startTime: { required }
        }
    },
    mounted() {
        this.getBranches();
        this.getThirdParties();
    },
    computed: {
        startTimes() {
            const startTimeList = []
            for (let i = 1; i <= 23; i++) {
                const time1 = this.convertTime(i)
                let ampmarrival = ''
                if (i >= 12) {
                    ampmarrival = 'PM'
                } else {
                    ampmarrival = 'AM'
                }
                const constime241 = i.toString() + ':00'
                const constime = time1.toString() + ':00 ' + ampmarrival
                startTimeList.push({ 'value': constime241, 'key': constime })
                const constime1 = time1.toString() + ':30 ' + ampmarrival
                const constime242 = i.toString() + ':30'
                startTimeList.push({ 'value': constime242, 'key': constime1 })
            }

            return startTimeList
        },
        endTimes() {
            const endTimeList = [];
            for (let j = 23; j > 0; j--) {
                const time1 = this.convertTime(j);
                let ampmarrival = '';
                if (j >= 12) {
                    ampmarrival = 'PM';
                } else {
                    ampmarrival = 'AM';
                }
                const constime241 = j.toString() + ':30';
                const constime1 = time1.toString() + ':30 ' + ampmarrival;
                endTimeList.push({ 'value': constime241, 'key': constime1 });
                const constime242 = j.toString() + ':00'
                const constime = time1.toString() + ':00 ' + ampmarrival
                endTimeList.push({ 'value': constime242, 'key': constime })
            }
            return endTimeList
        }

    },
    methods: {
        async createMenu() {

            this.spinner = 1;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = 0;
                return
            } else {
                const AvailabilityHours = []
                if (this.daysettings == 1) {
                    for (let y = 0; y < this.dayOfWeek.length; y++) {
                        AvailabilityHours.push({
                            'day': this.dayOfWeek[y].key,
                            'start_time': this.startTime[0],
                            'end_time': this.endTime[0]
                        })
                    }
                } else {
                    for (let x = 0; x < this.day.length; x++) {

                        AvailabilityHours.push({
                            "day": this.day[x],
                            "start_time": this.startTime[x],
                            "end_time": this.endTime[x]
                        })
                    }
                }
                const postData = {
                    "merchant_id": parseInt(this.merchantID),
                    'title': this.title,
                    'availability_hours': AvailabilityHours,
                    "branches": this.branches,
                    "contains_generated_offer_categories": this.allowOfferCategories,
                    "pos_outlet":this.tenderId
                    // "merchant_id": 1,
                    // "channels": this.thirdparties
                }
                const jsonParseData = JSON.stringify(postData)
                const url = this.baseUrl + "/menu";
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                this.axios.post(url, jsonParseData, config).then((response) => {
                    if (response.status == 201) {
                        toast.success("menu created successfully");
                        this.$router.push({
                            path: 'menu'
                        })
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                    }
                }).finally(() => this.spinner = 0)
                return;
            }
        },
        createPeriodCountDivs() {
            this.periodTimeCount.push({
                'key': Math.random()
            });
            return this.periodTimeCount;
        },
        async getThirdParties() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    const channels = response.data.data
                    for (let c = 0; c < channels.length; c++) {
                        this.coptions.push({
                            "value": channels[c].id,
                            "name": channels[c].channel_name
                        })
                    }
                }
            });
        },
        removePeriodCountDivs(ukey, day) {
            this.periodTimeCount = this.periodTimeCount.filter(
                (x) => x.key !== ukey
            );
            this.day = this.day.filter(
                (x) => x !== day
            )
            return this.periodTimeCount;
        },
        getBranches() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "//branch?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const branches = response.data.data;
                        for (let i = 0; i < branches.length; i++) {
                            this.options.push({
                                "value": branches[i].id,
                                "name": branches[i].branch_name
                            })
                        }
                    }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })

        }

    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]

}
</script>
